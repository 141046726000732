import React from "react";
import Loading from "../../../components/Loading";
/*#### fontawesome ###*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faRoute, faMapMarkerSmile, faQuestionCircle, } from "@fortawesome/pro-light-svg-icons";
import { ArrowUp, ContenedorMainMenu, MainMenuBoton, MainMenuLinksFijos, MenuPrincipalWrapper, } from "./MenuCategoriasStyled";
import ListaDeCategorias from "../ListaDeCategorias/ListaDeCategorias";
var MenuCategorias = function (_a) {
    var categorias = _a.categorias, configuraciones = _a.configuraciones;
    return (React.createElement(MenuPrincipalWrapper, null,
        React.createElement(MainMenuBoton, null,
            React.createElement(FontAwesomeIcon, { icon: faBars, size: "2x" }),
            React.createElement("span", null, "Men\u00FA")),
        React.createElement(ContenedorMainMenu, { id: "contenedor-main-menu" },
            React.createElement(ArrowUp, null),
            React.createElement(MainMenuLinksFijos, null,
                React.createElement("nav", null,
                    configuraciones && configuraciones.rastreoEnvio !== 0 && (React.createElement("a", { href: "/rastrea-tu-compra" },
                        React.createElement(FontAwesomeIcon, { icon: faRoute, size: "3x" }),
                        "Rastreo")),
                    React.createElement("a", { href: "ubicacion" },
                        React.createElement(FontAwesomeIcon, { icon: faMapMarkerSmile, size: "3x" }),
                        "Ubicaci\u00F3n"),
                    configuraciones && configuraciones.crmActivo !== 0 && (React.createElement("a", { href: "contacto" },
                        React.createElement(FontAwesomeIcon, { icon: faQuestionCircle, size: "3x" }),
                        "Ayuda")))),
            categorias && categorias.length > 0 ? (React.createElement(ListaDeCategorias, { categorias: categorias })) : (React.createElement(Loading, { elemento: "categorias-Lista" })))));
};
export default MenuCategorias;
