import React from "react";
import { useAppSelector } from "../../../hooks/customReduxHooks";
import CategoriasSegundoNivel from "../../CategoriasSegundoNivel";
import FabricantesListaMainMenu from "../../../components/FabricantesListaMainMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPumpMedical, faQuestionCircle, faStars, faUserHairLong, faShield, faLandmark, } from "@fortawesome/pro-light-svg-icons";
import { ContenedorMainMenu, ContenedorTyC, LogoEnMenuMobile, MenuPrincipalFondo, MenuPrincipalWrapper, MainMenuCategoriasEspeciales, ArrowUp, MainMenuCategorias, } from "./MenuCategoriasHorizontalesStyled";
var MenuCategoriasHorizontales = function (_a) {
    var categorias = _a.categorias, configuraciones = _a.configuraciones;
    var empresaData = useAppSelector(function (state) { return state.empresa; }).empresaData;
    var homeUrl = empresaData && empresaData.sitio_web;
    var linkEspecial = function () {
        var inicio = new Date("2023-11-06 00:00:00 GMT-0300");
        var final = new Date("2023-11-13 00:00:00 GMT-0300");
        var hoy = new Date();
        var timestampinicio = Math.floor(inicio.getTime() / 1000);
        var timestampfinal = Math.floor(final.getTime() / 1000);
        var timestamphoy = Math.floor(hoy.getTime() / 1000);
        return timestampinicio <= timestamphoy && timestampfinal >= timestamphoy;
    };
    return (React.createElement(MenuPrincipalFondo, { id: "fondo-contenedor-main-menu" },
        React.createElement(MenuPrincipalWrapper, { id: "contenedor-main-menu" },
            React.createElement(ContenedorMainMenu, null,
                React.createElement(LogoEnMenuMobile, { href: homeUrl }, empresaData && (React.createElement("img", { src: empresaData.logo, alt: empresaData.logo_titulo, title: empresaData.eslogan }))),
                React.createElement(MainMenuCategoriasEspeciales, null,
                    React.createElement("li", null,
                        React.createElement("a", { href: "landing/dermocosmetica" },
                            React.createElement(FontAwesomeIcon, { icon: faPumpMedical, size: "lg" }),
                            " ",
                            "Dermocosm\u00E9tica")),
                    React.createElement("li", null,
                        React.createElement("a", { href: "landing/marcas-premium" },
                            React.createElement(FontAwesomeIcon, { icon: faStars, size: "lg" }),
                            " Marcas Premium")),
                    React.createElement("li", null,
                        React.createElement("a", { href: "landing/cabellos-pro" },
                            React.createElement(FontAwesomeIcon, { icon: faUserHairLong, size: "lg" }),
                            " Cabello Pro")),
                    configuraciones && configuraciones.crmActivo !== 0 && (React.createElement("li", null,
                        React.createElement("a", { href: "contacto" },
                            React.createElement(FontAwesomeIcon, { icon: faQuestionCircle }),
                            "Ayuda")))),
                categorias && categorias.length > 0 && (React.createElement(MainMenuCategorias, { className: "main-menu-categorias" },
                    linkEspecial() && (React.createElement("li", { className: "link-ciberlunes" },
                        React.createElement("a", { href: "landing/ciberlunes" }, "Ciberlunes"))),
                    categorias.map(function (categoria, index) { return (React.createElement("li", { key: index },
                        React.createElement("a", { className: "menu-vinculo", href: categoria.urlseo },
                            categoria.nombre,
                            React.createElement(ArrowUp, null)),
                        categoria &&
                            categoria.hijos &&
                            categoria.hijos.length > 0 ? (React.createElement("nav", null,
                            React.createElement("h2", null,
                                React.createElement("a", { href: categoria.urlseo }, categoria.nombre)),
                            React.createElement("ul", null,
                                categoria &&
                                    categoria.hijos &&
                                    categoria.hijos.length > 0 &&
                                    categoria.hijos.map(function (children) { return (React.createElement(CategoriasSegundoNivel, { categoria: children })); }),
                                categoria &&
                                    categoria.fabricantes &&
                                    categoria.hijos.length > 0 && (React.createElement(FabricantesListaMainMenu, { fabricantes: categoria.fabricantes }))))) : null)); }))),
                React.createElement(ContenedorTyC, null,
                    React.createElement("a", { href: "/terminos" },
                        React.createElement(FontAwesomeIcon, { icon: faShield }),
                        " Privacidad, T\u00E9rminos y Condiciones"),
                    React.createElement("a", { href: "/politicas" },
                        React.createElement(FontAwesomeIcon, { icon: faLandmark }),
                        " Pol\u00EDticas de garant\u00EDa"))))));
};
export default MenuCategoriasHorizontales;
